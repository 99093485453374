<template>
  <page-layout-xiaowang
    bgColor="#f2f2f2"
    page-name="录入客户信息"
    :show-home="false"
    style="height: 100vh"
  >
    <div class="main">
<!--       <div class="tips" style="font-size: 16px;" v-else>第一步：录入客户信息</div>-->
      <van-form label-width="80px">
        <van-cell>
          <van-field
            label="企业名称"
            v-model="formData.nsrmc"
            maxlength="35"
            readonly
            required
            clickable
            placeholder="请输入企业名称"
            @click="handleEnterpriseSelectorShow"
          ></van-field>
<!--          <van-field-->
<!--            label="企业名称"-->
<!--            v-model="formData.nsrmc"-->
<!--            maxlength="35"-->
<!--            required-->
<!--            clickable-->
<!--            placeholder="请输入企业名称"-->
<!--          ></van-field>-->
        </van-cell>
        <van-cell>
          <van-field
            label="企业税号"
            v-model="formData.nsrsbh"
            maxlength="35"
            required
            clickable
            placeholder="请输入企业税号"
          ></van-field>
        </van-cell>
        <!-- <van-cell>
          <van-field
            label="法人姓名"
            v-model="formData.fddbrxm"
            maxlength="3"
            required
            clearable
            placeholder="请输入法人姓名"
          ></van-field>
        </van-cell>
        <van-cell>
          <van-field
            label="法人手机号"
            v-model="formData.fddbrsjh"
            type="number"
            maxlength="11"
            required
            clearable
            placeholder="请输入法人手机号"
          ></van-field>
        </van-cell> -->
      </van-form>
      <div class="apply-btn">
        <van-button type="primary" block :color="dljgThemeColor()" size="large" :loading="loading" loading-text="跳转中" @click="nextStep" >下一步</van-button>
      </div>
    </div>
    <enterprise-selector v-model="enterprise" ref="enterprise-selector"></enterprise-selector>
  </page-layout-xiaowang>
</template>

<script>
import {
  Button,
  Notify,
  Dialog,
  Form,
  Cell,
  Field,
} from "vant";
import PageLayoutXiaowang from '@/components/PageLayoutXiaowang.vue';
import EnterpriseSelector from "@/components/EnterpriseSelector";

export default {
  name: "ETaxSimpleAuthStep1",
  components: {
    [Button.name]: Button,
    [Notify.name]: Notify,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [Cell.name]: Cell,
    [Field.name]: Field,
    PageLayoutXiaowang,
    EnterpriseSelector,
  },
  props: {
    pCode: String,
    orgId: String,
    staffId: String,
    v: String,
    newOrder: {
      type: Boolean,
      default: false,
    },

  },
  create:function(){
    this.getThemeColor();
  },
  data() {
    return {
      pName: "",
      jgmc: this.$route.query.jgmc,
      dljgThemeColor() {
        if (this.$route.query.jgmc == "czbank") {
          return "#FF0000";
        } else {
          return "#F07F16";
        }
      },
      formData: {
        nsrmc: "",
        nsrsbh: "",
        fddbrxm: "",
        fddbrsjh: "",
      },
      enterprise: {
        enterpriseName: "",
        creditCode: "",
      },
      taxGrade: "",
      popup: {
        show: false,
        message: "",
      },
      loading: false,
    };
  },
  watch: {
    enterprise: {
      deep: true,
      handler(newVal) {
        this.formData.nsrmc = newVal.enterpriseName;
        this.formData.nsrsbh = newVal.creditCode;
      },
    },
  },
  mounted() {
  },
  methods: {
    showPopup(message) {
      this.popup.show = true;
      this.popup.message = message;
    },
    handleEnterpriseSelectorShow() {
      this.$refs["enterprise-selector"].open();
    },
    handleTaxGradeSelectorShow() {
      this.$refs["tax-grade-selector"].open();
    },
    nextStep() {
      if (!this.formData.nsrmc) {
        // Notify({ type: 'warning', message: '企业名称不能为空' });
        Dialog.alert({ message: "企业名称不能为空", confirmButtonText: "我知道了" });
        return;
      }
      if (!this.formData.nsrsbh) {
        // Notify({ type: 'warning', message: '企业税号不能为空' });
        Dialog.alert({ message: "企业税号不能为空", confirmButtonText: "我知道了" });
        return;
      }
      // if (!this.formData.fddbrxm) {
      //   Notify("法定代表人姓名不能为空");
      //   return;
      // }
      // if (!this.formData.fddbrsjh) {
      //   Notify("法定代表人手机号不能为空");
      //   return;
      // }
      this.loading = true;
      if (this.jgmc!=null||this.jgmc!=""){
        this.$router.push("/agent/etax-data/auth/"+this.formData.nsrsbh+"/"+this.formData.nsrmc+"?jgmc="+this.jgmc);
      }else{
        this.$router.push("/agent/etax-data/auth/"+this.formData.nsrsbh+"/"+this.formData.nsrmc);
      }

    },
  },
};
</script>

<style scoped>
.main {
  background-color: #ffffff;
  margin: 15px;
  border-radius: 15px;
  padding-bottom: 50px;
  font-size: 24px;
}

.tips {
  margin: 30px 30px 0px 30px;
  padding-top: 10PX;
  line-height: 80px;
}

.apply-btn {
  margin: 50px 12px 50px 12px;
}

.popup-message {
  padding: 30px 0;
  color: #ffffff;
  background-color: #ff976a;
  font-size: 36px;
  text-align: center;
}
</style>