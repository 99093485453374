<template>
  <div class="layout" :style="{ 'background-color': bgColor }">
    <div>
      <div v-if="jgmc==='czbank'"><img src="../assets/page_top_bg_czbank.png" style="width: 100%" /></div>
      <div v-else><img src="../assets/page_top_bg_xiaowang.png" style="width: 100%" /></div>
      <div class="header">
        <div class="nav">
          <router-link class="back-home" to="/" v-if="showHome">
            <md-icon name="home" size="lg" color="#ffffff" />
          </router-link>
          <div class="page-name">{{ pageName }}</div>
          <div class="icon" v-if="jgmc==='czbank'">
            <div class="ent-name_qian">CZBANK</div>
            <img src="../assets/logo_czbank.png" />
            <div class="ent-name">浙商银行</div>
          </div>
          <div  v-else class="icon">
            <img src="../assets/logo_xiaowang.png" />
            <div class="ent-name">小望科技</div>
          </div>
        </div>
        <div class="account-info" v-if="name">
          <div class="org-name">{{ org.name }}</div>
          <div class="staff-name">{{ name }}</div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "page-layout-xiaowang",
  data() {
    return {
      jgmc: this.$route.query.jgmc,
    };
  },
  props: {
    pageName: String,
    showHome: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: "#ffffff",
    },
    themeColor: {
      type: String,
      default: "#006400"
    },
  },
  computed: {
    ...mapState("login", ["name", "org"]),
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  position: absolute;
  top: 30px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-size: 24px;
}
.nav {
  display: flex;
  align-items: baseline;
}
.icon {
  display: flex;
  align-items: flex-end;
}
.icon img {
  width: 38px;
}
.icon .ent-name_qian {
  font-size: 28px;
  margin: 0 5px 0 10px;
  color: #d2d2d2;
}
.icon .ent-name {
  font-size: 28px;
  margin: 0 30px 0 5px;
  color: #d2d2d2;
}
.back-home {
  text-decoration: none;
  flex-grow: 0;
}

.back-home,
.page-name,
.crumbs-arrow {
  height: 41px;
  margin-left: 30px;
}

.page-name {
  font-size: 36px;
  flex-grow: 1;
  justify-self: center;
}

.account-info {
  margin: 50px 0 0 30px;
  color: #ffd95f;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.org-name {
  font-size: 42px;
  margin-bottom: 20px;
}
</style>